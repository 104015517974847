import * as React from 'react';
import {Slot} from '@radix-ui/react-slot';
import {cva, type VariantProps} from 'class-variance-authority';

import {cn} from '~/utils';

const buttonVariants = cva(
  [
    'relative inline-flex items-center justify-center gap-2 rounded text-center text-sm font-semibold shadow-3xl ring-offset-background transition-colors',
    'focus-visible:shadow-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
    'active:translate-x-[4px] active:translate-y-[4px] active:shadow-none',
    'disabled:pointer-events-none disabled:opacity-50',
  ],
  {
    variants: {
      variant: {
        default:
          'border border-background bg-primary text-background shadow-primary/50 hover:bg-primary/90 focus-visible:ring-primary active:bg-primary active:text-background',
        destructive:
          'bg-destructive text-destructive-foreground shadow-destructive/50 hover:bg-destructive/90',
        positive:
          'border border-background bg-secondary text-background shadow-secondary/50 hover:bg-secondary/90',
        outline:
          'border border-accent bg-background shadow-accent hover:bg-accent/20 focus-visible:ring-accent active:bg-accent active:text-accent-foreground',
        'outline-foreground':
          'border border-foreground bg-background text-foreground shadow-foreground hover:bg-foreground/20 focus-visible:ring-foreground active:bg-foreground active:text-background',
        'outline-primary':
          'border border-primary bg-background text-primary shadow-primary hover:bg-primary/20 focus-visible:ring-primary active:bg-primary active:text-background',
        accent:
          'bg-accent text-accent-foreground shadow-accent/60 hover:bg-accent/90',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-accent underline-offset-4 hover:underline',
      },
      size: {
        default: 'px-4 py-2',
        xs: 'rounded-md p-0.5',
        sm: 'rounded-md px-3 py-1',
        lg: 'rounded-md px-8 py-3',
        xl: 'rounded-lg px-10 py-4 text-2xl',
        icon: 'size-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      loading,
      variant,
      size,
      min,
      asChild = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({variant, size, className}))}
        ref={ref}
        {...props}
      >
        {loading ? (
          <>
            <span
              data-loading={true}
              className="absolute inset-0 flex items-center justify-center"
            >
              <span className="size-6 animate-spin rounded-full border-2 border-current border-t-transparent" />
            </span>
            <span className="text-transparent">{children}</span>
          </>
        ) : (
          children
        )}
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export {Button, buttonVariants};
